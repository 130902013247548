import {productServiceUrl} from './config'
import {ImageType, NormalizedProductDetail, OptionType, ProductDetail, ProductImage, VariantType} from '../config'
import {getElementFromHtmlString} from '../getElementFromHtmlString'
import {CurrencySymbol} from './getProductPrimitives'
import {logging} from '../utils/logging'
import {getMultiSourceImageWithDefaults} from '../config/context'
// import { stripHtml } from './utils/helpers'

export const normalizeImage = (productImage?: ProductImage): ImageType => {
  return {
    ...(getMultiSourceImageWithDefaults(productImage)),
  }
}

export const getCurrencySymbolFromCode = (currencyCode: string): CurrencySymbol => {
  if (currencyCode === 'USD') return '$'
  if (currencyCode === 'GBP') return '£'
  if (currencyCode === 'EUR') return '€'

  console.warn(`Missing currency symbol for ${currencyCode}`)
  // TODO: ideally this would never happen, make backend return the symbol instead
  return currencyCode as CurrencySymbol
}

// converts options to dictionary
//   uses index as position
// maps on variants and creates an options signature
//   adds currency symbol based on currency code
// converts description to react element
// normalizes image type
// merges images from featuredImage and images

export const normalizeProductDetail = (productDetail: ProductDetail): NormalizedProductDetail => {
  const options: {[optionName: string]: OptionType} = {}

  // TODO: temporary fix, remove when backend is deployed
  productDetail.options = productDetail.options?.filter(x => x)
  productDetail.images = productDetail.images || []
  productDetail.firstInStockVariant = productDetail.variants.find(v => v.available)?.title || productDetail.variants[0].title

  productDetail.options?.forEach((rawOption, index) => {
    options[rawOption.name] = {
      active: true,
      name: rawOption.name,
      position: index,
      value: rawOption.values[0],
      values: rawOption.values,
    }
  })

  const variants: VariantType[] = productDetail.variants.map(rawVariant => {
    // NOTE: the backend sends options in a particular order, so we need to honor that.
    const optionsMatch = productDetail.options
      .map(option => rawVariant.optionValues.find(x => x.name === option.name)?.value || '')
      .join(',')

    // TODO: should this be sent from backend, something like currency_symbol
    const currency = getCurrencySymbolFromCode(rawVariant.currencyCode)

    return {
      available: rawVariant.available,
      maxQuantity: rawVariant.quantity,
      id: rawVariant.id,
      externalId: rawVariant.externalId,
      currency,
      comparePrice: rawVariant.comparePrice,
      price: rawVariant.price,
      sku: rawVariant.sku,
      title: rawVariant.title,
      parentTitle: productDetail.title,
      parentImage: normalizeImage(productDetail.featuredImage),
      images: rawVariant.featuredImage ? [rawVariant.featuredImage].map(normalizeImage) : [],
      optionsMatch,
      optionValues: rawVariant.optionValues,
      allowOosOrder: rawVariant.allowOosOrder,
    }
  })

  return {
    id: productDetail.id,
    externalId: productDetail.externalId,
    description: getElementFromHtmlString(productDetail.description),
    title: productDetail.title,
    pdpUrl: productDetail.pdpUrl,
    cdpUrl: productDetail.cdpUrl,
    images: [
      productDetail.featuredImage,
      ...productDetail.images,
    ]
      .filter(x => x)
      .map(normalizeImage),
    options,
    variants,
    firstInStockVariant: productDetail.firstInStockVariant,
    type: 'PRODUCT_PRIMITIVE',
  }
}

export type GetProductDetailsOptions = {
  productIds?: string[]
  limit?: number
  offset?: number
}
export const getProductDetails = async(shop: string, options: GetProductDetailsOptions) => {
  try {
    const {productIds, limit, offset} = options

    const config : RequestInit = {
      method: 'GET',
      mode: 'cors',
      headers: {
        shop,
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
    }

    const query = {} as any
    if (productIds) query['productIds'] = productIds.join(',')
    if (limit) query['limit'] = limit
    if (offset) query['offset'] = offset

    const queryString = new URLSearchParams(query).toString()
    const url = `${productServiceUrl}/v1/products/?${queryString}`

    const response: ProductDetail[] = await fetch(url, config)
      .then(async (response) => {
        if (!response.ok) {
          const errBody = await response.json()
          throw new Error(`request failed with status ${response.status}: ${errBody.error}`)
        }
        return response.json()
      })
      .catch(err => {
        throw new Error(`fetch request failed: ${err}`)
      })

    return response.map(normalizeProductDetail)
  }
  catch (error) {
    logging(error, {tags: {section: 'getProductDetails'}})
    return []
  }
}

