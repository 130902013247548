export const clamp = (x: number, min: number = -Infinity, max: number = Infinity): number => Math.min(Math.max(x, min), max)

export const isNum = (val: string): boolean => /^\d+$/.test(val)

export const keepNumbers = (x: string): number => Math.round(Number(x.split('').filter(c => isNum(c)).join('')))

export const safeParseJSON = (str: any) => {
  let a
  try {
    a = JSON.parse(str)
  }
  catch (e) {
    return null
  }
  return a
}

// TODO: find a way to make the intelisense the same as Partial, instead of showing all of the subproperties as optional
export type DeepPartial<T> = T extends object ? {
  [P in keyof T]?: DeepPartial<T[P]>
} : T

// https://stackoverflow.com/a/20573538
export const isEmail = (str: string) => {
  const regexExp = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,63}$/gi
  return regexExp.test(str)
}

export const stripHtml = (html: string): string => html.replace(/<[^>]+>/g, '')

export const caseInsensitiveCompare = (str: string, filter: string): boolean => (str.toLowerCase().indexOf(filter.toLowerCase()) > -1)

export const isFramed = window.self !== window.top

// min and max included
export const randomIntFromInterval = (min: number, max: number) => {
  return Math.floor(Math.random() * (max - min + 1) + min)
}

export const contains = (list: Array<any>, item: any) => list.indexOf(item) > -1

export const asyncTimeout = (ms: number) => new Promise(resolve => setTimeout(resolve, ms))

export const isLocalhost = () => window.location.hostname === 'localhost'

export const getWebkitLineClamp = (n: number) => ({
  WebkitLineClamp: n,
  WebkitBoxOrient: 'vertical',
  overflow: 'hidden',
  display: '-webkit-box',
})

const isObject = (a: any) => {
  return (
    typeof a === 'object' &&
        !Array.isArray(a) &&
        a !== null
  )
}

// TODO: don't use (result as any)[key], https://github.com/microsoft/TypeScript/issues/47357
// TODO: ideally, this would return the old object if a and b are totally identical: as is, it will return a net new object.
// but if the new object and old object have properties that are identical, the new object's properties will reference the old object's properties.
// this is fine because our most of our container primitive react components have state that's dependant on the container object's properties, not on the container object itself.
// but its a bit counterintuitive.
type AnyObject = {[key: string]: any}
export const preserveListRefs = <T extends AnyObject>(a: T, b: T): T => {
  // if array, compare as strings and return original reference if equal
  if (Array.isArray(b)) return JSON.stringify(b) === JSON.stringify(a) ? a : b

  // if not objects return b
  if (!isObject(a) || !isObject(b)) return b

  // if objects recurse each property
  const result: T = {} as T
  Object.keys(b).forEach(key => {
    (result as any)[key] = preserveListRefs(a[key], b[key])
  })
  return result
}

export const deepCloneJson = <T>(x: T): T => JSON.parse(JSON.stringify(x))

// https://stackoverflow.com/questions/15298912/javascript-generating-combinations-from-n-arrays-with-m-elements
export const cartesian = (...args: any[]) => {
  const r: any[] = []
  const max = args.length - 1

  const helper = (arr: any[], i: number) => {
    for (let j = 0, l = args[i]?.length; j < l; ++j) {
      const a = arr.slice(0)
      a.push(args[i][j])
      if (i === max) r.push(a)
      else helper(a, i + 1)
    }
  }
  helper([], 0)
  return r
}

export const makeUrlBrowserCacheable = (url: string) => url.split('?')[0]

