import {useEffect, useContext, useState, lazy} from 'react'
import {ThemeProvider, ThemeOptions} from '@mui/material/styles'
import {createTheme} from '@mui/material/styles'
import {ConfigContext} from './config/context'
import {getDefaultTheme} from './utils/theme'
import {merge} from 'ts-deepmerge'
import {fontWithDefaults} from './defaultFonts'
import {logging} from './utils/logging'
import {getStyles} from './api/getStyles'
import {StyleFont, StylesResponse} from './config'
import {getPrimitiveSettings, PrimitiveSettings} from './api/primitiveSettings'
const Home = lazy(() => import('./components/Home'))
const CssBaseline = lazy(() => import('@mui/material/CssBaseline'))

export default function App() {
  const {searchParams, pageConfig, shop, shopDomain, setLogoUrl, setPrimitiveSettings} = useContext(ConfigContext)

  const [theme, setTheme] = useState(createTheme(getDefaultTheme()))

  useEffect(() => {
    if (!pageConfig) return
    document.title = pageConfig.headline
  }, [pageConfig])

  const getLastPath = ((string: string) => string.substring(string.lastIndexOf('/') + 1).replace(/[^a-zA-Z]+/g, ''))

  useEffect(() => {
    const asyncGetStyles = async () => {
      try {
        if (shop === '' && shopDomain === '') return // don't want to call this with the initial empty state for shop.
        const response: StylesResponse | undefined = await getStyles(shop, shopDomain)
        if (response) {
          // set the logo
          const logoUrl = response.logoUrl
          setLogoUrl(logoUrl)
          document.getElementById('favicon')?.setAttribute('href', logoUrl)
          document.getElementById('favicon-apple')?.setAttribute('href', logoUrl)

          // set the fonts and create a theme
          const fontFace: {fontFamily: string, src: string, fontDisplay: string}[] = []

          try {
            if (response.fonts?.main) {
              response.fonts.main.name = `SdxFontMain-${getLastPath(response.fonts.main.url)}`
            }
            if (response.fonts?.header) {
              response.fonts.header.name = `SdxFontHeader-${getLastPath(response.fonts.header.url)}`
            }
          }
          catch (error) {
            console.warn(error)
          }

          const fonts: StyleFont[] = [
            response.fonts.main,
            response.fonts.header,
          ]

          const fontMapper: {[key: string]: string} = {
            'woff': 'woff',
            'woff2': 'woff2',
            'ttf': 'truetype',
            'otf': 'opentype',
          }

          fonts.forEach(font => {
            if (!font.name) return

            const paramlessUrl = font.url.split('?')[0]
            const extensionIndex = paramlessUrl.lastIndexOf('.')
            const fontFormatType = paramlessUrl.substring(extensionIndex + 1) || ''
            const fontFormatValue = fontMapper[fontFormatType.toLowerCase()]

            if (!fontFormatValue) {
              console.warn(`Font format ${fontFormatType} not found in fontMapper.`)
            }
            fontFace.push({
              fontFamily: font.name,
              src: `url(${font.url})` + (fontFormatValue ? ` format(${fontFormatValue})`: ''),
              fontDisplay: 'swap',
            })
          })

          const newPartialTheme: Partial<ThemeOptions> = {
            components: {
              MuiCssBaseline: {
                styleOverrides: {
                  // NOTE: https://github.com/mui/material-ui/issues/24966#issuecomment-973929411
                  fallbacks: [
                    ...fontFace.map(f => ({'@font-face': f})),
                  ],
                },
              },
            },
            typography: {
              fontFamily: fontWithDefaults(response.fonts.main.name),
            },
            palette: {
              text: {
                primary: '#344054',
              },
              primary: {
                main: response.colors.primary,
              },
              secondary: {
                main: response.colors.secondary,
              },
            },
          }
          const newCustomTheme = {
            palette: {
              custom: {
                header_font_name: response.fonts.header?.name,
              } as any,
            },
          }
          const mergedTheme = merge.withOptions({mergeArrays: false},
            getDefaultTheme(),
            newPartialTheme,
            newCustomTheme,
          )
          setTheme(createTheme(mergedTheme))
        }
      }
      catch (error) {
        logging(error, {tags: {section: 'home app error'}})
      }
    }
    asyncGetStyles()
  }, [searchParams, shop, shopDomain, setLogoUrl])

  useEffect(() => {
    const asyncGetPrimitiveSettings = async () => {
      try {
        if (shop === '' && shopDomain === '') return // don't want to call this with the initial empty state for shop.
        const response: PrimitiveSettings | undefined = await getPrimitiveSettings(shop, shopDomain)
        if (response) {
          setPrimitiveSettings(response)
        }
      }
      catch (error) {
        logging(error, {tags: {section: 'home app error'}})
      }
    }
    asyncGetPrimitiveSettings()
  }, [shop, shopDomain, setPrimitiveSettings])

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Home />
    </ThemeProvider>
  )
}
