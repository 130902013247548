import {ReactElement} from 'react'
import {productServiceUrl} from './config'
import {ImageType, ProductImage} from '../config'
import {getElementFromHtmlString} from '../getElementFromHtmlString'
import {getCurrencySymbolFromCode, normalizeImage} from './getProductsByIds'
import {logging} from '../utils/logging'
import {PrimitiveType} from './components'

export type GetProductPrimitivesOptions = {
  productIds?: string[]
  categoryIds?: string[]

  comparisonProductId?: string
  blacklistedProductIds?: string[]

  limit?: number
  offset?: number
  filterBy?: 'SAME_CATEGORY' | 'IN_CATEGORY'
  sortBy?: 'TITLE' | 'PRICE' | 'SALES'

  sortDir?: 'ASC' | 'DESC'
}

export type ProductPrimitive = {
  id: string
  externalId: string
  title: string
  description: string
  pdpUrl?: string
  cdpUrl?: string
  featuredImage: ProductImage
  price: number
  comparePrice: number | null
  currencyCode: string
}

export type CurrencySymbol ='$'| '£'| '€'

export type NormalizedProductPrimitive = {
  id: string
  externalId: string
  title: string
  description: ReactElement
  url: string
  featuredImage: ImageType
  price: number
  comparePrice: number | null
  currency: CurrencySymbol
  type: PrimitiveType
}

export const NormalizeProductPrimitive = (productPrimitive: ProductPrimitive): NormalizedProductPrimitive => {
  // we can pass product primitives and category primitives into this function.
  // if there's a cdp field, then we have a category. otherwise, its a product.
  let type: PrimitiveType = 'PRODUCT_PRIMITIVE'

  // NOTE: Not all category primitives have a populated cdpUrl field, so we just want to check the existence of the field.
  //       Platforms like Salesforce and Feed currently pass an empty string for this and we still want to treat it as a category.
  //       https://ur2inc.atlassian.net/browse/DEV-2648
  if (productPrimitive.cdpUrl !== undefined) {
    type = 'CATEGORY_PRIMITIVE'
  }

  return {
    id: productPrimitive.id,
    externalId: productPrimitive.externalId,
    title: productPrimitive.title,
    description: getElementFromHtmlString(productPrimitive.description),
    url: productPrimitive.pdpUrl || productPrimitive.cdpUrl || '',
    featuredImage: normalizeImage(productPrimitive.featuredImage),
    price: productPrimitive.price,
    comparePrice: productPrimitive.comparePrice,
    currency: getCurrencySymbolFromCode(productPrimitive.currencyCode),
    type: type,
  }
}

export const getProductPrimitives = async (shop: string, options: GetProductPrimitivesOptions, categoryEndpoint?: boolean) => {
  try {
    const {productIds, blacklistedProductIds, categoryIds, limit, offset, filterBy, sortBy, sortDir, comparisonProductId} = options

    const config : RequestInit = {
      method: 'GET',
      mode: 'cors',
      headers: {
        shop,
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
    }

    const query = {} as any
    if (productIds) query['productIds'] = productIds.join(',')
    else if (categoryIds) query['categoryIds'] = categoryIds.join(',')

    if (categoryIds && blacklistedProductIds) query['blacklistedProductIds'] = blacklistedProductIds.join(',')
    if (comparisonProductId) query['comparisonProductId'] = comparisonProductId

    if (limit) query['limit'] = limit
    if (offset) query['offset'] = offset

    // NOTE: refactor this
    const _filterBy = categoryIds?.length ? 'IN_CATEGORY' : filterBy
    if (_filterBy && !categoryEndpoint) query['filterBy'] = _filterBy
    // If there is no product or category to filter by, do not send the filterBy query parameter
    if (!query['productIds'] && !query['categoryIds']) delete query['filterBy']
    if (sortBy) query['sortBy'] = sortBy
    if (sortDir) query['sortDir'] = sortDir

    const queryString = new URLSearchParams(query).toString()

    const path = categoryEndpoint ? 'categories' : 'products'

    const url = `${productServiceUrl}/v1/${path}/primitives/?${queryString}`

    const response: ProductPrimitive[] = await fetch(url, config)
      .then(async (response) => {
        if (!response.ok) {
          const errBody = await response.json()
          throw new Error(`request failed with status ${response.status}: ${errBody.error}`)
        }
        return response.json()
      })
      .catch(err => {
        throw new Error(`fetch request failed: ${err}`)
      })

    return response.map(NormalizeProductPrimitive)
  }
  catch (error) {
    logging(error, {tags: {section: 'getProductPrimitives'}})
    return []
  }

}
