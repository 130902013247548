import {ContainerConfig} from '../api/components'

export const getInitialHeaderConfig = (): ContainerConfig => {
  const now = new Date().getTime()
  return ({
    visibleInLibrary: false,
    status: 'PUBLISHED',
    createdAt: now,
    updatedAt: now,
    componentType: 'HEADER',
    containerType: 'GRID',
    contentType: 'HEADER',
    internalDescription: 'Header',
    internalName: 'Header',
    title: '',
    componentId: 'uninitialized_header',
    contentConfiguration: {
      type: 'HEADER',
      value: {
        uiSettings: {
          ribbon: {
            backgroundColor: '',
            textColor: '',
            font: {
              size: 14,
            },
            height: 20,
            scrolling: {
              direction: 'HORIZONTAL',
              speed: 1,
            },
          },
          logo: {
            backgroundColor: '',
          },
        },
        apiSettings: {
          logo: {
            url: '',
          },
          ribbon: {
            font: {
              url: '',
            },
            values: [],
          },
        },
      },
    },
    class: 'HOOK',
  })
}
